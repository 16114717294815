import React from "react";
import Hero from "../components/hero";
import "./home.scss"
import logo from '../assets/rohLogo_white.png';
import apiImage from '../assets/api.png'
import storeImage from '../assets/store.png'
import Card from "../components/card";


class Home extends React.Component {
  public render() {
    return (
      <div className="page">
        <div className="logo-banner"><img src={logo} className="logo"></img>Ring of Health</div>
        <Hero backgroundColor="#5171a0" textColor="#ddd" title="Ring of Health" description="Ring of health can track your medical records on a tiny ring and allow you control over how your medical data is used!" link="http://portal.ringofhealth.com/" linkText="Log in" />
        <div className="row">
          <div className="col-sm-6">
            <Card image={storeImage} title="Buy devices" description="You can buy rings, and other devices from our store!" link="http://shop.ringofhealth.com/" linkText="Shop" />
          </div>
          <div className="col-sm-6">
            <Card image={apiImage} title="Utilize our API" description="Use our API to create your own applications for the ring of health devices" link="http://api.ringofhealth.com/swagger" linkText="Check it out" />
          </div>
        </div>
      </div>
    )
  }
}

export default Home;