import React from 'react';
import './hero.scss';

interface IHeroProps {
  title: string
  description?: string
  link?: string
  image?: string
  linkText?: string
  backgroundColor?: string
  textColor?: string
}
class Hero extends React.Component<IHeroProps, any> {
  public render() {
    const background = "linear-gradient(0deg, #fff 0%, " + this.props.backgroundColor + " 15%," + this.props.backgroundColor + " 100%)";
    return (
      <div className="px-4 text-center hero-container" style={{ background: background, color: this.props.textColor }} >
        <img src={this.props.image} className="hero-image img-fluid" alt="ring" />
        <h1 className="display-5 fw-bold">{this.props.title}</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">{this.props.description}</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a href={this.props.link} className="btn btn-primary btn-lg px-4">{this.props.linkText}</a>
          </div>
        </div>
      </div>);
  }
}

export default Hero;