

import React from 'react';
import './card.scss';

interface IHeroProps {
  title: string
  description?: string
  link?: string
  image?: string
  linkText?: string
}
class Card extends React.Component<IHeroProps, any> {
  public render() {

    return (
      <div className="card">
        <img src={this.props.image} className="card-img-top img-fluid" />
          <div className="card-body">
            <h5 className="card-title">{this.props.title}</h5>
            <p className="card-text">{this.props.description}</p>
            <a href={this.props.link} className="btn btn-primary">{this.props.linkText}</a>
          </div>
      </div>
    );
  }
}

export default Card;